import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mision',
  templateUrl: './mision.component.html',
  styleUrls: ['./mision.component.scss']
})
export class MisionComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  regresar(){ this.router.navigate(['/landing']);}

}
