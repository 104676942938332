import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.scss']
})
export class VisionComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  regresar(){ this.router.navigate(['/landing']);}
  
}
